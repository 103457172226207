<template>
  <div class="container-fluid p-10">
    <h1 class="text-primary fs-1 mb-5 text-center">Assessment List</h1>

    <div class="table-responsive">
      <table
        v-if="!loadingTable"
        class="table table-borderless assessment-list align-middle table-hover"
      >
        <thead class="table-light table-primary text-white">
          <tr>
            <th v-for="items in header" :key="items" class="p-3">
              {{ items }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="items in assessmentList"
            :key="items"
            class="text-dark my-3 text-capitalize cursor-pointer"
            @click="rowSelect(items)"
          >
            <td class="p-3">
              {{ items.id }}
            </td>
            <td class="p-3">
              {{
                items.patient.patient_details &&
                items.patient.patient_details.first_name
                  ? `${items.patient.patient_details.first_name} ${items.patient.patient_details.middle_name} ${items.patient.patient_details.last_name}`
                  : '-'
              }}
            </td>
            <td class="p-3">
              {{
                items.referral.referrer && items.referral.referrer.first_name
                  ? `${items.referral.referrer.first_name} ${items.referral.referrer.middle_name} ${items.referral.referrer.last_name}`
                  : '-'
              }}
            </td>
            <td class="p-3">
              {{
                items.patient.claim_number ? items.patient.claim_number : '-'
              }}
            </td>
            <td class="w-50 p-3">
              {{ items.selected }}
            </td>
            <td class="p-3">
              {{ items.date_time_created }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="d-flex justify-content-center w-100 h-100 overflow-hidden my-5 py-5"
      >
        <div
          class="spinner-border text-primary text-center"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <nav v-if="!loadingTable" aria-label="table-navigation">
      <ul class="pagination justify-content-center">
        <li
          class="page-item"
          :class="currentPage === 1 || currentPage === null ? 'disabled' : ''"
        >
          <button
            class="page-link"
            tabindex="-1"
            type="button"
            aria-label="Previous"
            :disabled="currentPage === 1 || currentPage === null ? true : false"
            @click="loadPrev()"
          >
            Previous
          </button>
        </li>
        <li
          v-for="item in computedPagination"
          :key="item"
          class="page-item"
          :class="currentPage == item ? 'active' : ''"
        >
          <button
            class="page-link"
            type="button"
            aria-current="page"
            @click="loadTable(item)"
          >
            {{ item }}
          </button>
        </li>
        <li class="page-item" :class="pageData.next ? '' : 'disabled'">
          <button
            type="button"
            class="page-link"
            :disabled="pageData.next ? false : true"
            aria-label="Next"
            :aria-disabled="pageData.next ? false : true"
            @click="loadNext()"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { api } from '@/axios-api'
import dayjs from 'dayjs'

export default {
  name: 'AssessmentList',
  components: {},

  data() {
    return {
      pageReady: false,
      header: [
        'ID',
        'Patient',
        'Referrer',
        'Claim Num',
        'Assessed Body Parts',
        'Created On',
      ],
      assessmentList: [],
      totalBodyParts: [
        'elbow',
        'finger_index',
        'finger_middle',
        'finger_pinky',
        'finger_ring',
        'finger_thumb',
        'wrist',
        'shoulder',
      ],
      currentPage: null,
      pageData: {
        prev: null,
        next: null,
      },
      paginationLength: 0,
      loadingTable: true,
    }
  },

  computed: {
    computedPagination: function () {
      //start with 5 pages
      let numbers = []

      for (let i = 1; i <= this.paginationLength && numbers.length < 5; i++) {
        numbers.push(i)
      }

      let currentIndex = numbers.indexOf(this.currentPage)

      //if user selected page number is not center of array then run...
      if (
        this.currentPage > 2 &&
        currentIndex !== 2 &&
        this.pageData.next !== null
      ) {
        //time for new array
        numbers = []

        //rerender before and after number of selected page to ensure selected number stays center

        for (let i = this.currentPage - 1; i >= this.currentPage - 2; i--) {
          numbers.unshift(i)
        }

        for (
          let i = this.currentPage + 1;
          i <= this.currentPage + 2 && i <= this.paginationLength;
          i++
        ) {
          numbers.push(i)
        }

        //push selected page back to center of list
        numbers.splice(2, 0, this.currentPage)

        return numbers
      }

      return numbers
    },
  },

  async mounted() {
    await this.getAssessmentList()
  },

  methods: {
    //function to tidy up recieved table datas
    filterTableData(ary) {
      //clean up list array
      this.assessmentList = []

      ary.forEach((e) => {
        let selected = e.patient.symptoms.map((s) => s.body_part)

        e['date_time_created'] = dayjs(e.date_time_created).format(
          'YYYY-MM-DD h:ma'
        )

        //SELECTED BODY PARTS
        e['selected'] = selected

        if (e['selected'].length > 0) {
          e['selected'] = selected.join(', ')
        } else e['selected'] = '-'

        this.assessmentList.push(e)
      })

      //stop loading
      this.loadingTable = false
    },

    async getAssessmentList() {
      //get clicked page number data
      await api
        .get(`${'assessment/'}`)
        .then((response) => {
          const RESPONSE = response.data

          this.pageData.prev = RESPONSE.previous
          this.pageData.next = RESPONSE.next
          this.paginationLength = RESPONSE.count

          this.filterTableData(RESPONSE.results)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    loadTable(value) {
      //loading table data
      this.loadingTable = true

      //load table data list
      this.currentPage = value

      //get clicked page number data
      api
        .get(`${'assessment/?page=' + value}`)
        .then((response) => {
          const RESPONSE = response.data

          this.pageData.prev = RESPONSE.previous
          this.pageData.next = RESPONSE.next

          this.filterTableData(RESPONSE.results)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    loadPrev() {
      //loading table data
      this.loadingTable = true

      if (this.pageData.prev) {
        api
          .get(`${this.pageData.prev}`)
          .then((response) => {
            const RESPONSE = response.data

            this.pageData.prev = RESPONSE.previous
            this.pageData.next = RESPONSE.next

            this.filterTableData(RESPONSE.results)
          })

          .catch(() => {
            alert('Sorry, there no previous page available. ')
          })
      }

      //stop loading
      else this.loadingTable = false
    },

    loadNext() {
      //loading table data
      this.loadingTable = true

      if (this.pageData.next) {
        api
          .get(`${this.pageData.next}`)
          .then((response) => {
            const RESPONSE = response.data

            this.pageData.prev = RESPONSE.previous
            this.pageData.next = RESPONSE.next

            this.filterTableData(RESPONSE.results)
          })

          .catch(() => {
            alert('Sorry, there no more next page. ')
          })
      }

      //stop loading
      this.loadingTable = false
    },

    rowSelect(row) {
      // this.$store.commit('STORE_ROW_SELECTED_ASSESSMENT', row)

      //load to assesment building page
      this.$router.push({
        name: `assessment-building`,
        params: { id: row.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.assessment-list {
  font-weight: 300;
  font-size: 16px;

  border-collapse: separate;
  border-spacing: 0 15px;
  tbody {
    tr {
      td {
        border: 1px solid rgb(224, 224, 224);
        background: white;

        &:first-child {
          border-right: 0;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        &:last-child {
          border-left: 0;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        &:not(:first-child):not(:last-child) {
          border-right: 0;
          border-left: 0;
        }
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
